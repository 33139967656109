import React from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { SEO } from "../../utils/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { PageHeader } from "../../shared/PageHeader/PageHeader"
import { Link } from "gatsby"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"
import { H1, H2 } from "../../shared/Ui/Typography/Typography"

const Container = styled.div`
    max-width: 800rem;
    margin: 50rem auto 0;

    h1 {
        font-size: 40rem;
        margin-top: 5rem;
    }

    h2 {
        font-size: 18rem;
        line-height: 30rem;
    }

    h3 {
        font-size: 28rem;
        margin-top: 60rem;
        margin-bottom: 0;
        color: ${({ theme }) => theme.text.normal};
        font-family: "Gilroy-Bold", sans-serif;
    }

    h4 {
        font-size: 20rem;
        margin-top: 30rem;
        margin-bottom: 10rem;
        font-family: "Gilroy-SemiBold", sans-serif;
        color: ${({ theme }) => theme.text.normal};
    }

    h2,
    h3,
    h4 {
        &:hover {
            .anchor.before svg {
                visibility: visible;
            }
        }

        .anchor.before {
            position: absolute;
            left: -25rem;
            padding-right: 10rem;

            svg {
                visibility: hidden;

                &:hover {
                    fill: ${({ theme }) => theme.primary};
                }
            }
        }
    }

    p,
    li {
        color: ${({ theme }) => theme.text.normal};
        line-height: 27rem;
    }

    p {
        line-height: 27rem;
    }

    li {
        //margin: 0 0 5rem;
    }
`

const RoleSimple = ({ location, ...props }) => {
    const context = props.pageContext

    return (
        <Page constrained location={location} disableCta>
            <SEO
                location={location}
                title={context.frontmatter.title}
                description={context.frontmatter.description}
            />
            <Container>
                <Link className={"return-link"} to={"/careers"} tabIndex={-1}>
                    <TextButton tabIndex={0} color={"primary"} fontWeight={600}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24rem"
                            viewBox="0 0 24 24"
                            width="24rem"
                            fill="currentColor"
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                        </svg>
                        Back to open roles
                    </TextButton>
                </Link>
                <H1>{context.frontmatter.title}</H1>
                <H2>{context.frontmatter.description}</H2>
                <MDXRenderer children={context.body} />
            </Container>
        </Page>
    )
}

export default RoleSimple
